<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Choices from "choices.js";

import avatar_icon from '@/assets/images/users/avatar-1.jpg'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import {getBrokerageApi} from "@/api/brokerage"
import {uploadFile} from '@/api/misc'


 import {
  required,
  sameAs
} from "vuelidate/lib/validators";


/**
 * New Staff component
 */
export default {
  page: {
    title: "Staff",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  props: {
     staff : {
      type : Object,
      default: () => ({
        roles : [],
        offices:[]
      })
     },
    
  },

  data() {
    return {
      title: "Brokerage",
      items: [
        {
          text: "Staff",
          href: "/brokerage/staff/grid",
        },
        {
          text: "New",
          active: true,
        },
      ],
      avatar: avatar_icon,
      edit : false,
      role_choice: {},
      office_choice: {},
      office_list :[],
      role_list :[]
    };
  },


  components: {
    Layout,
    PageHeader,
    flatPickr
  },

  validations()  {
      if (this.edit) {
        return {
          staff: {
              first_name: { required },
              last_name: { required },
          }
        }
      } else {
        return {
          staff: {
              first_name: { required },
              last_name: { required },
              login_name: { required },
              password: { required },
              confirm_password: { required, sameAsPassword: sameAs("password") },
          },
        }
    }
  },

  methods: {

    formSubmit() {
      this.$v.$touch();     
      if (this.$v.$error == false) { 
          if (this.staff.id) {
            getBrokerageApi().staffApi.modify_staff(this.staff).then((res) => {
              if (res.errCode == 0) {
                this.$router.push({ path: '/brokerage/staff/grid' })
              } else {
                this.$alertify.error("Add Staff Failed "+ res.errCode);
              }
            })
          } else {
            getBrokerageApi().staffApi.create_new_staff(this.staff).then((res) => {
              if (res.errCode == 0) {
                this.$router.push({ path: '/brokerage/staff/grid' })
              } else {
                this.$alertify.error("Add Staff Failed "+ res.errCode);
              }
            })
          }
         
      }
    },

    chooseAvatar() {
      this.$refs.avatarFile.click()
    },

    avatarChanged(evt) {
      if (evt.target.files.length <=0) {
          return
      }
      let formData = new FormData()
      formData.append("file", evt.target.files[0]);
      uploadFile(formData).then((res) =>{
          if (res[0].errCode == 200) {
              this.staff.avatar = res[0].response.url
              this.avatar= res[0].response.url
          } 
      })
    }
   
  },

  beforeUpdated() {
    
  },

  mounted() {
    new Choices("#choices-multiple-language-input", {
      removeItemButton: true,
    });

  
    this.role_choice = new Choices("#choices-role-input", {
      removeItemButton: true,
    });

    this.office_choice = new Choices("#choices-office-input", {
      removeItemButton: true,
    });

   
    getBrokerageApi().officeApi.list().then( (res) => {
        res.data.map((f)=>{
         
          let select = this.staff.offices.find((e) => e == f.id) ? true : false
          this.office_list.push({
            label : f.branch_name,
            value : f.id,
            selected : select,
            office : f
          })
        })
        this.office_choice.setChoices(this.office_list)
    })

    getBrokerageApi().officeApi.role_list().then( (res) => {
      
        res.data.map((f)=>{
          let select = this.staff.roles.find((e) => e == f) ? true : false
          this.role_list.push({
            label : f,
            value : f,
            selected: select
          })
        })
       
        this.role_choice.setChoices(this.role_list)
    })


    this.avatar = this.staff.avatar
    this.edit = this.staff.id ? true : false

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-4">New Staff</h5>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        01
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">General Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">First Name</label>
                          <input type="text" class="form-control" v-model="staff.first_name" id="gen-info-name-input" placeholder="Enter Name"  
                            :class="{
                                    'is-invalid':  $v.staff.first_name.$error,
                                }" 
                          />
                          <div v-if=" $v.staff.first_name.$error" class="invalid-feedback">
                              <span v-if="!$v.staff.first_name.required">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-designation-input">Last Name</label>
                          <input type="text" class="form-control" id="gen-info-designation-input"
                            placeholder="Enter Last Name"
                            v-model="staff.last_name" 
                            :class="{
                                    'is-invalid':  $v.staff.last_name.$error,
                                }" 
                          />
                          <div v-if=" $v.staff.last_name.$error" class="invalid-feedback">
                              <span v-if="!$v.staff.last_name.required">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 text-center">
                        <input  ref="avatarFile"  class="form-control" @change="avatarChanged" type="file" id="avatarFile" style="opacity: 0;width : 1px; height:1px" />
                        <div class="position-relative d-inline-block mx-auto mt-n5 mb-4">
                          <div class="avatar-lg">
                            <img :src="avatar" alt="" class="rounded-circle img-thumbnail" />
                          </div>
                          <a href="#" class="d-block position-absolute bottom-0 end-0" @click="chooseAvatar">
                            <div class="avatar-sm">
                              <div class="avatar-title rounded-circle bg-light text-primary">
                                <i class="mdi mdi-pencil"> </i>
                              </div>
                            </div>
                          </a>
                          
                        </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label" for="gen-info-description-input">Description</label>
                      <textarea class="form-control" placeholder="Enter Description" id="gen-info-description-input"
                        rows="3"></textarea>
                    </div>

                    <div>
                      <label for="choices-multiple-language-input" class="form-label">Language</label>
                      <select class="form-control" name="choices-multiple-language-input" id="choices-multiple-language-input"
                        multiple>
                        <option value="Photoshop" >English</option>
                        <option value="illustrator" >Chinese</option>
                        <option value="Spanish">Spanish</option>
                      </select>
                    </div>

                  <div class="row">
                    <label for="choices-role-input" class="form-label">Role</label>
                    <select class="form-control"  v-model="staff.roles"  name="choices-role-input" id="choices-role-input" multiple>
                    </select>
                  </div>

                  <div class="row">
                    <label for="choices-office-input" class="form-label">Office</label>
                    <select class="form-control" v-model="staff.offices" name="choices-office-input" id="choices-office-input" multiple>
                    </select>
                  </div>

                  </div>
                </div>
              </div>
              <!-- end card -->


              <div class="card border shadow-none mb-5" v-if="!this.edit">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        02
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Account Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="contact-info-login-name-input">Login Name :</label>
                        <input type="text" class="form-control" id="contact-info-login-name-input"
                          placeholder="Enter Login Name" 
                          v-model="staff.login_name"
                          :class="{
                                    'is-invalid':  $v.staff.login_name.$error,
                                }" 
                              
                          />
                          <div v-if=" $v.staff.login_name.$error" class="invalid-feedback">
                              <span v-if="!$v.staff.login_name.required">This value is required.</span>
                          </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="contact-info-vin-input">VIN :</label>
                        <input type="number" class="form-control" id="contact-info-vin-input"
                          placeholder="Enter VIN" 
                          v-model="staff.vin"
                          />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-md-0">
                        <label for="contact-info-website-input" class="form-label">Password</label>
                        <input type="password" autocomplete="off" class="form-control" placeholder="Enter passwrod"
                        v-model="staff.password"
                        :class="{
                                    'is-invalid':  $v.staff.password.$error,
                                }" 
                          />
                          <div v-if=" $v.staff.password.$error" class="invalid-feedback">
                              <span v-if="!$v.staff.password.required">This value is required.</span>
                          </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-md-0">
                        <label for="contact-info-confirm-password-input" class="form-label">Confirm Password</label>
                        <input type="password" class="form-control" autocomplete="off"  placeholder="Confrim password"
                          v-model="staff.confirm_password"
                        :class="{
                                    'is-invalid':  $v.staff.confirm_password.$error,
                                }" 
                          />
                          <div v-if=" $v.staff.confirm_password.$error" class="invalid-feedback">
                              <span v-if="!$v.staff.confirm_password.required">This value is required.</span>
                              <span
                                v-else-if="!$v.staff.confirm_password.sameAsPassword"
                                >This value should be the same with passwrod.</span
                              >
                          </div>
                         
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card -->

              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        03
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Contact Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="contact-info-email-input">Email :</label>
                        <input type="email" v-model="staff.email"  class="form-control" id="contact-info-email-input"
                          placeholder="Enter Email" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="contact-info-phone-input">Phone :</label>
                        <input type="number"  v-model="staff.mobile" class="form-control" id="contact-info-phone-input"
                          placeholder="Enter phone" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-md-0">
                        <label for="contact-info-birthday-input" class="form-label">Birthday</label>
                        <flat-pickr
                          v-model="staff.birthday"
                          placeholder="Select a date"
                          class="form-control"
                          ></flat-pickr>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Location</label>
                        <input type="url" class="form-control" placeholder="Enter Address"
                          id="contact-info-location-input" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card -->

              <div class="card border shadow-none">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        04
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Experience</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="workexperience-designation-input">Designation</label>
                        <input type="text" class="form-control" id="workexperience-designation-input"
                          placeholder="Enter Designation title" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="workexperience-companyname-input">Company name</label>
                        <input type="text" class="form-control" id="workexperience-companyname-input"
                          placeholder="Enter Company name" />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label>Years</label>
                    <input type="text" class="form-control" id="datepicker-range" />
                  </div>
                </div>
              </div>
              <!-- end card -->

              <div class="text-end">
                <button type="submit" class="btn btn-primary w-sm">
                  Submit
                </button>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
@import "~choices.js/public/assets/styles/choices.min.css";
</style>
